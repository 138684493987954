import React from "react"

import { DriveLayout } from "components/layout-custom"
import Vehicles from "components/vehicles-old"

const Page = () => (
  <DriveLayout title="Live Vehicle Data" padded={false}>
    <Vehicles type="driver" />
  </DriveLayout>
)

export default Page
